import {
  CButton,
  CForm,
  CFormGroup,
  CInput,
  CInputCheckbox,
  CInvalidFeedback,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from "@coreui/react"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import ButtonExport from "src/assets/icons/button-export.svg"
import exportProcessSystem from "src/requests/process-system/ExportProcessSystem"
import apiCallWrapper from "src/helpers/ApiCallWrapper"
import getCopyProcessSystem from "src/requests/process-system/GetCopyProcessSystem"
import previewExportProcessSystem from "src/requests/process-system/PreviewExportProcessSystem"
import PreviewProcessingDataModal from "./PreviewProcessingDataModal"

const ExportModal = ({ id, showModal, itemId, onClosed }) => {
  const [loading, setLoading] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const dispatch = useDispatch()
  const [hasDraft, setHasDraft] = useState()
  const [previewExportData, setPreviewExportData] = useState()
  const [showPreviewExportDataModal, setShowPreviewExportDataModal] = useState(false)

  const { register, handleSubmit, reset, errors, control } = useForm({})

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked)
  }

  const getCopyHandler = () => {
    apiCallWrapper.call(getCopyProcessSystem, dispatch, {
      successHandler: (jsonData) => {
        if (jsonData) {
          setHasDraft(jsonData.hasDraft)
        }
      },
      params: {
        id: itemId,
      },
    })
  }

  const previewExportProcessSystemHandler = () => {
    apiCallWrapper.call(previewExportProcessSystem, dispatch, {
      successHandler: (jsonData) => {
        setPreviewExportData(jsonData)
        setShowPreviewExportDataModal(true)
        onClosed()
      },
      params: {
        id: id,
      },
    })
  }

  const exportProcessSystemHandler = () => {
    apiCallWrapper.call(exportProcessSystem, dispatch, {
      successHandler: (jsonData) => {
        let filename = `${jsonData.processSystem.title}` + ".json"
        let contentType = "application/json;charset=utf-8;"
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          var blob = new Blob([decodeURIComponent(encodeURI(JSON.stringify(jsonData)))], {
            type: contentType,
          })
          navigator.msSaveOrOpenBlob(blob, filename)
        } else {
          var a = document.createElement("a")
          a.download = filename
          a.href = "data:" + contentType + "," + encodeURIComponent(JSON.stringify(jsonData))
          a.target = "_blank"
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
        setLoading(false)
        setIsChecked(false)
        onClosed()
        setShowPreviewExportDataModal(false)
      },
      successMessage: "Data successfully exported",
      params: {
        processSystemId: itemId,
        exportDraft: isChecked,
      },
    })
  }

  const onSubmit = async (formData) => {
    setLoading(true)
    exportProcessSystemHandler()
  }

  useEffect(() => {
    if (itemId) {
      getCopyHandler()
    }
  }, [itemId])

  return (
    <>
      <CModal
        closeOnBackdrop={false}
        style={{
          borderRadius: 0,
          padding: 20,
          paddingLeft: 30,
          paddingRight: 30,
          borderColor: "white",
        }}
        show={showModal}
        onClosed={() => {
          onClosed()
        }}
      >
        <CForm onSubmit={handleSubmit(onSubmit)}>
          <CModalHeader className="modal-heading">
            <CModalTitle className="modal-heading-title">
              <img src={ButtonExport} className="heading-img" alt="copy" />
              Export System
            </CModalTitle>
          </CModalHeader>
          <CModalBody className="modal-body">
            <CFormGroup>
              <CModalHeader style={{ marginTop: "-20px" }}>
                <div className="w-100 text-left mt-4 mb-2">
                  <p>
                    <span className="modal-span">
                      This action will export the structure of this system template into a json
                      file.
                    </span>
                  </p>
                </div>
              </CModalHeader>
              <CInvalidFeedback className="help-block">{errors.title?.message}</CInvalidFeedback>
            </CFormGroup>
            <CFormGroup variant="checkbox" className="checkbox" hidden={!hasDraft}>
              <CInputCheckbox
                id="checkbox"
                name="checkbox"
                value="option"
                checked={isChecked}
                onChange={handleCheckboxChange}
                disabled={false}
              />
              <CLabel variant="checkbox" htmlFor="agreement" className="label-agreement">
                Check this box if you wish to export all data entries from the original system as
                well
              </CLabel>
            </CFormGroup>
          </CModalBody>
          <CModalFooter className="modal-footer-border-width">
            <div className="modal-button-container">
              <div className="modal-button-wrapper modal-button-padding-right text-align">
                <CButton
                  className="btn-pill btn-cancel"
                  onClick={() => {
                    reset({})
                    onClosed()
                    setIsChecked(false)
                  }}
                >
                  Cancel
                </CButton>
              </div>
              <div className="modal-button-wrapper modal-button-padding-left">
                <CButton
                  className="btn-pill btn-save-changes"
                  onClick={previewExportProcessSystemHandler}
                  disabled={loading}
                >
                  Preview Export
                </CButton>
              </div>
            </div>
          </CModalFooter>
        </CForm>
      </CModal>
      <PreviewProcessingDataModal
        data={previewExportData}
        show={showPreviewExportDataModal}
        title={"Data Export Preview"}
        btnTitle={"Proceed to Export"}
        isImport={false}
        onClose={() => {
          setShowPreviewExportDataModal(false)
        }}
        onConfirm={exportProcessSystemHandler}
      />
    </>
  )
}

export default ExportModal
