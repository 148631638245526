import { CCard, CCardBody, CModal, CModalBody } from "@coreui/react"
import { useEffect, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { useLocation } from "react-router-dom/cjs/react-router-dom.min"
import ButtonDelete from "src/assets/icons/button-delete.svg"
import ButtonPlus from "src/assets/icons/button-plus.svg"
import ButtonUse from "src/assets/icons/button-use.svg"
import ButtonView from "src/assets/icons/button-view.svg"
import HeadingList from "src/assets/icons/heading-list.svg"
import apiCallWrapper from "src/helpers/ApiCallWrapper"
import requestHelper from "src/helpers/RequestHelper"
import deleteProcessSystem from "src/requests/process-system/DeleteProcessSystem"
import deleteProcessesSystems from "src/requests/process-system/DeleteProcessesSystems"
import getProcessSystems from "src/requests/process-system/GetProcessSystems"
import CarbonDataTable from "src/reusable/CarbonDataTable"
import PaginationSection from "src/reusable/PaginationSection"
import ProcessSystemDeleteModal from "src/views/process-template/delete-process/ProcessSystemDeleteModal"
import ButtonExport from "src/assets/icons/button-export.svg"
import ButtonImport from "src/assets/icons/button-import.svg"
import ButtonCopy from "src/assets/icons/button-copy.svg"
import ProcessSystemCopyModal from "../edit/ProcessSystemCopyModal"
import ExportModal from "../processing-data/ExportModal"
import { useCallback } from "react"
import previewImportProcessSystem from "src/requests/process-system/PreviewImportProcessSystem"
import { useDropzone } from "react-dropzone"
import importProcessSystem from "src/requests/process-system/ImportProcessSystem"
import PreviewProcessingDataModal from "../processing-data/PreviewProcessingDataModal"
import ProcessSystemEditDetails from "../edit/ProcessSystemEditDetails"
import addProcessSystem from "src/requests/process-system/AddProcessSystem"

const ProcessSystemList = ({ onDelete, updateCounter }) => {
  let history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const [showActions, setShowActions] = useState(false)
  const [showActionsId, setShowActionsId] = useState("")
  const [objects, setObjects] = useState([])
  const [initialObjects, setInitialObjects] = useState([])
  const [isChecked, setIsChecked] = useState(false)
  const [checkedProcessSystemTemplatesList, setCheckedProcessSystemTemplatesList] = useState([])
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [processSystem, setProcessSystem] = useState({})

  const [showCopyModal, setShowCopyModal] = useState(false)
  const [showExportModal, setShowExportModal] = useState(false)
  const [showPreviewImportDataModal, setShowPreviewImportDataModal] = useState(false)
  const [copyProcessSystemId, setCopyProcessSystemId] = useState()
  const [previewImportData, setPreviewImportData] = useState()
  const [dataToImport, setDataToImport] = useState({})

  const [showBasicDetailsModal, setShowBasicDetailsModal] = useState()
  const processSystemTitle = useRef("")
  const processSystemDescription = useRef("")
  const processSystemDeclaration = useRef("")
  const processSystemAllocation = useRef("")
  const processSystemCutOffRules = useState("")
  const processSystemSite = useRef()

  const [totalPages, setTotalPages] = useState(0)
  const filters = useRef({})
  const activePage = useRef()
  const pageSize = useRef()

  const urlSearchParams = new URLSearchParams(location.search)
  const pageParam = urlSearchParams.get("page") || 1
  const sizeParam = urlSearchParams.get("size") || 10
  const titleParam = urlSearchParams.get("title") || ""
  const descriptionParam = urlSearchParams.get("description") || ""
  const createdByParam = urlSearchParams.get("createdBy") || ""
  const validFilterParamNames = ["title", "description", "createdBy"]
  const isAdminOrDataManager =
    requestHelper.hasRole("Admin") || requestHelper.hasRole("Data Manager")

  const getProcessTemplatesHandler = (filters, activePage, pageSize) => {
    const params = { ...filters }
    params.page = activePage - 1
    params.size = pageSize
    apiCallWrapper.call(getProcessSystems, dispatch, {
      successHandler: (jsonData) => {
        jsonData.content.forEach((element) => {
          initialObjects.forEach((object) => {
            if (object.id === element.id) {
              element.isChecked = object.isChecked
            }
          })
        })
        setObjects(modifyDateHandler(jsonData.content))
        setInitialObjects((prevState) => {
          return uniqueById([...prevState, ...jsonData.content], false)
        })
        setTotalPages(Number(jsonData.totalPages))
        updateCounter(Number(jsonData.totalElements))
      },
      params: params,
    })
  }

  const addProcessSystemHandler = (process) => {
    apiCallWrapper.call(addProcessSystem, dispatch, {
      successHandler: (jsonData) => {
        history.replace("/process-system/edit/" + jsonData.id)
      },
      params: process,
    })
  }

  const saveProcessSystem = () => {
    const requestObject = {
      title: processSystemTitle.current,
      description: processSystemDescription.current,
      declaration: processSystemDeclaration.current,
      allocationRules: processSystemAllocation.current,
      cutOffRules: processSystemCutOffRules.current,
      siteId: processSystemSite.current,
      startDate: null,
      endDate: null,
      processes: [],
      connections: [],
    }
    addProcessSystemHandler(requestObject)
  }

  const modifyDateHandler = (array) => {
    let result = []
    array.map((system) => {
      let lastModifiedDate = new Date(system.lastModifiedDate.split("T")[0])
      let createdDate = new Date(system.createdDate.split("T")[0])
      let format = { year: "numeric", month: "long", day: "numeric" }
      let lastModifiedDateFormatted = lastModifiedDate.toLocaleString("en-US", format)
      let createdDateFormatted = createdDate.toLocaleString("en-US", format)
      system.lastModifiedDate = lastModifiedDateFormatted
      system.createdDate = createdDateFormatted
      result.push(system)
    })
    return result
  }

  const deleteProcessSystemHandler = (id) => {
    apiCallWrapper.call(deleteProcessSystem, dispatch, {
      successHandler: () => {
        getProcessTemplatesHandler(filters.current, activePage.current, pageSize.current)
      },
      successMessage: "System has been deleted",
      params: {
        id: id,
      },
    })
  }

  const deleteProcessesSystemsHandler = (items) => {
    apiCallWrapper.call(deleteProcessesSystems, dispatch, {
      successHandler: () => {
        uncheckTemplatesHandler()
        getProcessTemplatesHandler(filters.current, activePage.current, pageSize.current)
        onDelete()
      },
      successMessage: "System has been deleted",
      params: items,
    })
  }

  const previewImportProcessSystemHandler = (data) => {
    apiCallWrapper.call(previewImportProcessSystem, dispatch, {
      successHandler: (jsonData) => {
        setPreviewImportData(jsonData)
        setShowPreviewImportDataModal(true)
      },
      params: {
        processSystemDto: data.processSystemDto,
        objectLciRelation: data.objectLciRelation,
        fileName: data.fileName,
        draft: data.draft
      },
    })
  }

  const importProcessSystemHandler = () => {
    apiCallWrapper.call(importProcessSystem, dispatch, {
      successHandler: (jsonData) => {
        setShowPreviewImportDataModal(false)
        getProcessTemplatesHandler(filters.current, activePage.current, pageSize.current)
      },
      successMessage: "Data successfully imported.",
      params: {
        processSystemDto: dataToImport.processSystemDto,
        objectLciRelation: dataToImport.objectLciRelation,
        processSystem: dataToImport.processSystem,
        fileName: dataToImport.fileName,
        objectLciRelation: dataToImport.objectLciRelation,
        draft: dataToImport.draft,
      },
    })
  }

  const checkedTemplatesHandler = (item) => {
    let checked = false
    let templatesList = [...initialObjects]
    let array = []
    templatesList.forEach((template) => {
      if (template.id === item.id) {
        template.isChecked = !template.isChecked
      }
      if (template.isChecked) {
        array.push(template)
        checked = true
      }
    })
    setObjects((prevState) => {
      return uniqueById([...prevState, ...templatesList], true)
    })
    setIsChecked(checked)
    setCheckedProcessSystemTemplatesList(array)
  }

  const uncheckTemplatesHandler = () => {
    let templatesList = [...objects]
    let initialTemplates = [...initialObjects]
    templatesList.forEach((obj) => (obj.isChecked = false))
    initialTemplates.forEach((obj) => (obj.isChecked = false))
    setIsChecked(false)
    setObjects(templatesList)
    setInitialObjects(initialTemplates)
  }

  const uniqueById = (items, returnDuplicate) => {
    const set = new Set()
    return items
      .filter((item) => {
        const isDuplicate = set.has(item.id)
        set.add(item.id)
        return returnDuplicate ? isDuplicate : !isDuplicate
      })
      .sort((a, b) => {
        return b.id - a.id
      })
  }

  const onDrop = useCallback((acceptedFiles) => {
    let file = acceptedFiles[0]
    const reader = new FileReader()
    reader.onabort = () => console.log("file reading was aborted")
    reader.onerror = () => console.log("file reading has failed")
    reader.onload = () => {
      const binaryStr = reader.result
      let parsedJson = JSON.parse(new TextDecoder().decode(binaryStr))
      const data = {}
      const processSystemDto = JSON.stringify(parsedJson.processSystemDto)
      const processSystem = JSON.stringify(parsedJson.processSystem)
      const draft = JSON.stringify(parsedJson.draft)
      const objectLciRelation = JSON.stringify(parsedJson.objectLciRelation)
      data.processSystemDto = processSystemDto
      data.processSystem = processSystem
      data.draft = draft
      data.objectLciRelation = objectLciRelation
      data.fileName = file.path
      setDataToImport(data)
      previewImportProcessSystemHandler(data)
    }
    reader.readAsArrayBuffer(file)
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  useEffect(() => {
    if (!pageParam) return
    if (!sizeParam) return

    filters.current = {}
    urlSearchParams.forEach((value, name) => {
      if (validFilterParamNames.includes(name)) {
        filters.current[name] = value
      }
    })

    activePage.current = Number(pageParam)
    pageSize.current = Number(sizeParam)

    getProcessTemplatesHandler(filters.current, activePage.current, pageSize.current)
  }, [pageParam, sizeParam, titleParam, descriptionParam, createdByParam])
  return (
    <>
      <CCard className="cs-card">
        <CCardBody>
          <div className="heading">
            <img src={HeadingList} className="heading-img" alt="icon-list" />
            Systems Templates
            {isAdminOrDataManager ? (
              <>
                <img
                  src={ButtonDelete}
                  onClick={() => {
                    setShowDeleteModal(isChecked)
                  }}
                  className={isChecked ? "action-img-archive" : "action-img-disabled"}
                  style={{
                    marginLeft: isChecked ? "8px" : "",
                    marginTop: "10px",
                  }}
                  align="right"
                  alt="Delete"
                  title="Delete"
                />
                {isAdminOrDataManager ? (
                  <span {...getRootProps()}>
                    <input {...getInputProps()} />
                    <img
                      src={ButtonImport}
                      className="action-img"
                      style={{ marginTop: "10px" }}
                      align="right"
                      alt="Import Data"
                      title="Import"
                    />
                  </span>
                ) : (
                  ""
                )}
                <img
                  src={ButtonPlus}
                  alt="Create a process template"
                  title="Create a process template"
                  style={{ marginTop: "10px" }}
                  className="action-img"
                  align="right"
                  onClick={() => {
                    setShowBasicDetailsModal(true)
                  }}
                />
              </>
            ) : (
              ""
            )}
          </div>

          <CarbonDataTable
            useCheckBox={isAdminOrDataManager}
            checkedItems={checkedTemplatesHandler}
            items={objects}
            loading={!objects}
            fields={[
              { key: "title", label: "Name" },
              { key: "createdDate", label: "Created On" },
              { key: "lastModifiedDate", label: "Last Modified" },
              {
                key: "actions",
                label: "Created By",
                _style: { width: "250px" },
              },
            ]}
            sorter
            onRowHover={(item, index, e, action) => {
              if (action === "enter") {
                setShowActions(true)
                setShowActionsId(item.id)
              } else {
                setShowActions(false)
                setShowActionsId("")
              }
            }}
            onFirstColumnNameClick={(item) => {
              history.push("/process-system/edit/" + item.id)
            }}
            scopedSlots={{
              actions: (item) => (
                <td>
                  {showActions && item.id === showActionsId ? (
                    <div style={{ textAlign: "right" }}>
                      <img
                        src={ButtonView}
                        className="action-img"
                        alt="Edit System"
                        title="Edit System"
                        onClick={() => {
                          history.push("/process-system/edit/" + item.id)
                        }}
                      />
                      {isAdminOrDataManager ? (
                        <img
                          src={ButtonCopy}
                          className="action-img"
                          alt="Copy"
                          title="Copy"
                          onClick={() => {
                            setShowCopyModal(true)
                            setCopyProcessSystemId(item.id)
                          }}
                        />
                      ) : (
                        ""
                      )}
                      {isAdminOrDataManager ? (
                        <img
                          src={ButtonExport}
                          className="action-img"
                          alt="Export"
                          title="Export Data"
                          onClick={() => {
                            setShowExportModal(true)
                            setCopyProcessSystemId(item.id)
                            // setCopyProcessSystemId(item.id)
                          }}
                        />
                      ) : (
                        ""
                      )}
                      {isAdminOrDataManager ? (
                        <img
                          src={ButtonDelete}
                          className="action-img action-img-archive"
                          alt="Delete"
                          title="Delete"
                          onClick={() => {
                            uncheckTemplatesHandler()
                            setProcessSystem(item)
                            setShowDeleteModal(true)
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    <div className="div-action">
                      {item.createdBy.length > 30
                        ? `${item.createdBy.substring(0, 30)}...`
                        : item.createdBy}
                    </div>
                  )}
                </td>
              ),
            }}
          />
          <PaginationSection
            pageParam={pageParam}
            sizeParam={sizeParam}
            totalPages={totalPages}
            onChange={(activePageParam, pageSizeParam) => {
              urlSearchParams.set("page", activePageParam)
              urlSearchParams.set("size", pageSizeParam)
              history.push({ search: urlSearchParams.toString() })
              activePage.current = activePageParam
              pageSize.current = pageSizeParam
            }}
          />
        </CCardBody>
      </CCard>
      <ProcessSystemDeleteModal
        show={showDeleteModal}
        isChecked={isChecked}
        item={processSystem}
        items={checkedProcessSystemTemplatesList}
        onDeleteItems={(items) => deleteProcessesSystemsHandler(items)}
        onDeleteOneItem={(id) => deleteProcessSystemHandler(id)}
        onClose={() => setShowDeleteModal(false)}
      />
      <ProcessSystemCopyModal
        showModal={showCopyModal}
        itemId={copyProcessSystemId}
        onClosed={() => {
          setShowCopyModal(false)
          setCopyProcessSystemId(false)
        }}
        onCopy={() => {
          getProcessTemplatesHandler(filters.current, activePage.current, pageSize.current)
        }}
      />
      <ExportModal
        id={copyProcessSystemId}
        showModal={showExportModal}
        itemId={copyProcessSystemId}
        onClosed={() => {
          setShowExportModal(false)
        }}
      />
      <PreviewProcessingDataModal
        data={previewImportData}
        show={showPreviewImportDataModal}
        title={"Data Import Preview"}
        btnTitle={"Proceed to Import"}
        isImport={true}
        onClose={() => {
          setShowPreviewImportDataModal(false)
        }}
        onConfirm={importProcessSystemHandler}
      />
      <CModal
        closeOnBackdrop={false}
        size="md"
        onClosed={() => {
          setShowBasicDetailsModal(false)
        }}
        className={"modal-transparent"}
        show={showBasicDetailsModal}
      >
        <CModalBody className="modal-body">
          <CCard className="cs-card">
            <CCardBody>
              <ProcessSystemEditDetails
                initialData={""}
                onClose={() => setShowBasicDetailsModal(false)}
                onSubmit={() => {
                  setShowBasicDetailsModal(false)
                  saveProcessSystem()
                }}
                onNameChange={(name) => {
                  processSystemTitle.current = name
                }}
                onDescriptionChange={(description) => {
                  processSystemDescription.current = description
                }}
                onSiteChange={(site) => {
                  processSystemSite.current = site
                }}
                onDeclarationChange={(declaration) => {
                  processSystemDeclaration.current = declaration
                }}
                onAllocationChange={(allocation) => {
                  processSystemAllocation.current = allocation
                }}
                onCutOffRulesChange={(cutOffRules) => {
                  processSystemCutOffRules.current = cutOffRules
                }}
                isEditable={true}
              />
            </CCardBody>
          </CCard>
        </CModalBody>
      </CModal>
    </>
  )
}

export default ProcessSystemList
