import React, { useEffect, useState } from "react"
import {
  CModalHeader,
  CModalTitle,
  CModal,
  CModalBody,
  CFormGroup,
  CLabel,
  CInput,
  CModalFooter,
  CButton,
} from "@coreui/react"
import { default as HeadingIcon } from "../../../assets/icons/heading-carbon-sig-logo.svg"

const PreviewProcessingDataModal = ({
  show,
  onClose,
  data,
  title,
  btnTitle,
  onConfirm,
  isImport,
}) => {
  const [hasNotExportedData, setHasNotExportedData] = useState()
  const [hasDataInLocalLibrary, setHasDataInLocalLibrary] = useState()
  const [showNotExportedDataSection, setShowNotExportedDataSection] = useState()

  const onCloseHandler = () => {
    onClose()
  }

  const hasNotExportedDataHandler = () => {
    if (
      data.restrictedEmissionFactorsTitleForPreview.length > 0 ||
      data.restrictedLciTitlesForPreview.length > 0
    ) {
      setHasNotExportedData(true)
    } else {
      setHasNotExportedData(false)
    }
  }

  const showNotExportedDataSectionHandler = () => {
    if (
      data.restrictedEmissionFactorsTitleForPreview.length > 0 ||
      data.restrictedLciTitlesForPreview.length > 0 ||
      data.capsForPreview.length > 0
    ) {
      setShowNotExportedDataSection(true)
    } else {
      setShowNotExportedDataSection(false)
    }
  }

  const hasDataInLocalLibraryHandler = () => {
    let hasObjectInLocalLibrary =
      data.objectTitlesForPreview.length > 0 &&
      data.objectTitlesForPreview.some((title) => title.substring(title.length - 1) === "*")
    let hasLciInLocalLibrary =
      data.lciTitlesForPreview.length > 0 &&
      data.lciTitlesForPreview.some((title) => title.substring(title.length - 1) === "*")
    let hasEmfInLocalLibrary =
      data.emissionFactorsTitleForPreview.length > 0 &&
      data.emissionFactorsTitleForPreview.some((title) => title.substring(title.length - 1) === "*")
    let hasObjectLciConnectionInLocalLibrary =
      data.objectLciConnection.length > 0 &&
      data.objectLciConnection.some((title) => title.substring(title.length - 1) === "*")

    if (
      hasObjectInLocalLibrary ||
      hasLciInLocalLibrary ||
      hasEmfInLocalLibrary ||
      hasObjectLciConnectionInLocalLibrary
    ) {
      setHasDataInLocalLibrary(true)
    } else {
      setHasDataInLocalLibrary(false)
    }
  }

  useEffect(() => {
    if (data) {
      hasNotExportedDataHandler()
      hasDataInLocalLibraryHandler()
      showNotExportedDataSectionHandler()
    }
  }, [data])

  return (
    <CModal
      closeOnBackdrop={false}
      style={{
        borderRadius: 0,
        padding: 20,
        paddingLeft: 30,
        paddingRight: 30,
        borderColor: "white",
        bottom: 20,
      }}
      show={show}
      onClose={onClose}
    >
      <CModalHeader className="modal-heading">
        <CModalTitle className="span-heading">
          <img className="heading-img" src={HeadingIcon} />
          {title}
        </CModalTitle>
      </CModalHeader>
      <CModalBody className="pt-0 pb-0">
        <div
          style={{ borderRadius: "12px" }}
          className="p-3 mb-4 w-100 d-flex flex-column border border-1"
        >
          <CFormGroup>
            <CLabel className="rfi-label" htmlFor={"input"}>
              System Template
            </CLabel>
            {data && (
              <CInput
                key={"title"}
                style={{ borderRadius: "2px", marginBottom: "2px" }}
                id={"input"}
                disabled
                name={"input"}
                value={data.processSystemTitle}
              />
            )}
          </CFormGroup>
          {data && isImport && data.processesInProcessSystem.length > 0 ? (
            <CFormGroup>
              <CLabel className="rfi-label" htmlFor={"input"}>
                Processes
              </CLabel>
              {data.processesInProcessSystem.map((title, index) => {
                return (
                  <div>
                    <CInput
                      key={"parent-input-" + index}
                      style={{ borderRadius: "2px", marginBottom: "2px" }}
                      id={"input"}
                      disabled
                      name={"input"}
                      value={title ?? ""}
                    />
                  </div>
                )
              })}
            </CFormGroup>
          ) : (
            ""
          )}
          {data && data.objectTitlesForPreview.length > 0 ? (
            <CFormGroup>
              <CLabel className="rfi-label" htmlFor={"input"}>
                Objects
              </CLabel>
              {data.objectTitlesForPreview.map((title, index) => {
                return (
                  <div>
                    <CInput
                      key={"parent-input-" + index}
                      style={{ borderRadius: "2px", marginBottom: "2px" }}
                      id={"input"}
                      disabled
                      name={"input"}
                      value={title ?? ""}
                    />
                  </div>
                )
              })}
            </CFormGroup>
          ) : (
            ""
          )}
          {data && data.lciTitlesForPreview.length > 0 ? (
            <CFormGroup>
              <CLabel className="rfi-label" htmlFor={"input"}>
                LCIs
              </CLabel>
              {data.lciTitlesForPreview.map((title, index) => {
                return (
                  <div>
                    <CInput
                      key={"parent-input-" + index}
                      style={{ borderRadius: "2px", marginBottom: "2px" }}
                      id={"input"}
                      disabled
                      name={"input"}
                      value={title ?? ""}
                    />
                  </div>
                )
              })}
            </CFormGroup>
          ) : (
            ""
          )}
          {data && data.emissionFactorsTitleForPreview.length > 0 ? (
            <CFormGroup>
              <CLabel className="rfi-label" htmlFor={"input"}>
                Emission Factors
              </CLabel>
              {data.emissionFactorsTitleForPreview.map((title, index) => {
                return (
                  <div>
                    <CInput
                      key={"parent-input-" + index}
                      style={{ borderRadius: "2px", marginBottom: "2px" }}
                      id={"input"}
                      disabled
                      name={"input"}
                      value={title ?? ""}
                    />
                  </div>
                )
              })}
            </CFormGroup>
          ) : (
            ""
          )}
          {data && data.objectLciConnection.length > 0 ? (
            <CFormGroup>
              <CLabel className="rfi-label" htmlFor={"input"}>
                Object - LCI Connections
              </CLabel>
              {data.objectLciConnection.map((title, index) => {
                return (
                  <div>
                    <CInput
                      key={"parent-input-" + index}
                      style={{ borderRadius: "2px", marginBottom: "2px" }}
                      id={"input"}
                      disabled
                      name={"input"}
                      value={title ?? ""}
                    />
                  </div>
                )
              })}
            </CFormGroup>
          ) : (
            ""
          )}
        </div>

        {showNotExportedDataSection ? (
          <div
            style={{ borderRadius: "12px" }}
            className="p-3 mb-4 w-100 d-flex flex-column border border-1"
          >
            {hasNotExportedData ? (
              <CFormGroup>
                <CLabel className="rfi-label">Not exported</CLabel>
              </CFormGroup>
            ) : (
              ""
            )}
            {data && data.restrictedLciTitlesForPreview.length > 0 ? (
              <CFormGroup>
                <CLabel className="rfi-label" htmlFor={"input"}>
                  LCIs
                </CLabel>
                {data.restrictedLciTitlesForPreview.map((title, index) => {
                  return (
                    <div>
                      <CInput
                        key={"parent-input-" + index}
                        style={{
                          borderRadius: "2px",
                          marginBottom: "2px",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                        id={"input"}
                        disabled
                        name={"input"}
                        value={title ?? ""}
                      />
                    </div>
                  )
                })}
              </CFormGroup>
            ) : (
              ""
            )}
            {data && data.restrictedEmissionFactorsTitleForPreview.length > 0 ? (
              <CFormGroup>
                <CLabel className="rfi-label" htmlFor={"input"}>
                  Emission Factors
                </CLabel>
                {data.restrictedEmissionFactorsTitleForPreview.map((title, index) => {
                  return (
                    <div>
                      <CInput
                        key={"parent-input-" + index}
                        style={{ borderRadius: "2px", marginBottom: "2px" }}
                        id={"input"}
                        disabled
                        name={"input"}
                        value={title ?? ""}
                      />
                    </div>
                  )
                })}
              </CFormGroup>
            ) : (
              ""
            )}
            {data && data.capsForPreview.length > 0 ? (
              <CFormGroup>
                <CLabel className="rfi-label">
                  CAPs - These objects will be exported as empty inputs
                </CLabel>
                {data.capsForPreview.map((title, index) => {
                  return (
                    <div>
                      <CInput
                        key={"parent-input-" + index}
                        style={{ borderRadius: "2px", marginBottom: "2px" }}
                        id={"input"}
                        disabled
                        name={"input"}
                        value={title ?? ""}
                      />
                    </div>
                  )
                })}
              </CFormGroup>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
        {isImport && hasDataInLocalLibrary ? (
          <p>*These data are already in your local library</p>
        ) : (
          ""
        )}
      </CModalBody>
      <CModalFooter className="modal-footer-border-width">
        <div className="modal-button-container">
          <div className="modal-button-wrapper modal-button-padding-right text-align">
            <CButton className="btn-pill btn-cancel" onClick={onCloseHandler}>
              Cancel
            </CButton>
          </div>
          <div className="modal-button-wrapper modal-button-padding-left">
            <CButton type="submit" className="btn-pill btn-save-changes" onClick={onConfirm}>
              {btnTitle}
            </CButton>
          </div>
        </div>
      </CModalFooter>
    </CModal>
  )
}

export default PreviewProcessingDataModal
