import {
  CButton,
  CForm,
  CFormGroup,
  CInput,
  CInputCheckbox,
  CInvalidFeedback,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from "@coreui/react"
import { yupResolver } from "@hookform/resolvers/yup"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import DuplicateIcon from "src/assets/icons/duplicate-icon.svg"
import apiCallWrapper from "src/helpers/ApiCallWrapper"
import * as Yup from "yup"
import copyProcessSystem from "src/requests/process-system/CopyProcessSystem"
import getCopyProcessSystem from "src/requests/process-system/GetCopyProcessSystem"

const ProcessSystemCopyModal = ({ showModal, itemId, onClosed, onCopy }) => {
  const [dto, setDto] = useState({})
  const [loading, setLoading] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const [hasDraft, setHasDraft] = useState()
  const dispatch = useDispatch()

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .trim()
      .required("Title is required")
      .max(65, "Field limit is 65 characters"),
  })
  const { register, handleSubmit, reset, errors, control } = useForm({
    defaultValues: {
      title: dto.title,
    },
    resolver: yupResolver(validationSchema),
  })

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked)
  }

  const getCopyHandler = () => {
    apiCallWrapper.call(getCopyProcessSystem, dispatch, {
      successHandler: (jsonData) => {
        if (jsonData) {
          setHasDraft(jsonData.hasDraft)
          setDto(jsonData.dto)
          const cleanProcessSystemTitle =
            jsonData.dto && jsonData.dto.title ? jsonData.dto.title : ""

          const newCopyNumber = jsonData.count > 0 ? jsonData.count : 1
          const match = cleanProcessSystemTitle.match(/\((\d+)\)/)

          const newTitle =
            match === null
              ? `${cleanProcessSystemTitle.trim()} (${newCopyNumber})`
              : cleanProcessSystemTitle.replace(/\(\d+\)/, `(${newCopyNumber})`)

          reset({
            title: newTitle,
          })
        }
      },
      params: {
        id: itemId,
      },
    })
  }

  const copyProcessSystemHandler = (title) => {
    dto.title = title
    apiCallWrapper.call(copyProcessSystem, dispatch, {
      successHandler: (jsonData) => {
        setLoading(false)
        onClosed()
        onCopy()
        setIsChecked(false)
      },
      errorHandler: () => {
        setLoading(false)
        setIsChecked(false)
      },
      successMessage: "System has been copied",
      params: {
        copyDraftChecked: isChecked,
        dto: dto,
        processSystemId: itemId,
      },
    })
  }

  const onSubmit = async (formData) => {
    setLoading(true)
    copyProcessSystemHandler(formData.title)
  }

  useEffect(() => {
    if (itemId) {
      getCopyHandler()
    }
  }, [itemId])

  return (
    <>
      <CModal
        closeOnBackdrop={false}
        style={{
          borderRadius: 0,
          padding: 20,
          paddingLeft: 30,
          paddingRight: 30,
          borderColor: "white",
        }}
        show={showModal}
        onClosed={() => {
          onClosed()
        }}
      >
        <CForm onSubmit={handleSubmit(onSubmit)}>
          <CModalHeader className="modal-heading">
            <CModalTitle className="modal-heading-title">
              <img src={DuplicateIcon} className="heading-img" alt="copy" />
              Duplicate System
            </CModalTitle>
          </CModalHeader>
          <CModalBody className="modal-body">
            <CFormGroup>
              <CModalHeader style={{ marginTop: "-20px" }}>
                <div className="w-100 text-left mt-4 mb-2">
                  <p>
                    <span className="modal-span">
                      This action will duplicate the structure of this system template into a new
                      system.
                    </span>
                  </p>
                </div>
              </CModalHeader>

              <CLabel htmlFor="title" style={{ marginTop: "20px" }}>
                New System Name
              </CLabel>
              <CInput
                className="modal-body-input-border-radius"
                id="title"
                name="title"
                placeholder="Enter Title"
                innerRef={register}
                invalid={!!errors.title}
              />
              <CInvalidFeedback className="help-block">{errors.title?.message}</CInvalidFeedback>
            </CFormGroup>
            <CFormGroup variant="checkbox" className="checkbox" hidden={!hasDraft}>
              <CInputCheckbox
                id="checkbox"
                name="checkbox"
                value="option"
                checked={isChecked}
                onChange={handleCheckboxChange}
                disabled={false}
              />
              <CLabel variant="checkbox" htmlFor="agreement" className="label-agreement">
                Check this box if you wish to copy over all data entries from the original system as
                well
              </CLabel>
            </CFormGroup>
          </CModalBody>
          <CModalFooter className="modal-footer-border-width">
            <div className="modal-button-container">
              <div className="modal-button-wrapper modal-button-padding-right text-align">
                <CButton
                  className="btn-pill btn-cancel"
                  onClick={() => {
                    reset({})
                    onClosed()
                    setIsChecked(false)
                  }}
                >
                  Cancel
                </CButton>
              </div>
              <div className="modal-button-wrapper modal-button-padding-left">
                <CButton
                  type="submit"
                  className="btn-pill btn-save-changes"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  Duplicate
                </CButton>
              </div>
            </div>
          </CModalFooter>
        </CForm>
      </CModal>
    </>
  )
}

export default ProcessSystemCopyModal
